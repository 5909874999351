import React from 'react'
import { Modal, Form, Input, Radio, Message, Row, Col, Button, Select } from 'antd'
import { Flex } from 'antd-mobile'
import Upload from 'rc-upload'
import { baseApiUrl } from 'config'
import { get, getStore } from 'mulan-lib'
import { processRequest } from 'lib'
const { Option } = Select

class StoreForm extends React.Component {
  constructor(props) {
    super(props)
    const { detailObj = {} } = props
    const { list = [], basePic } = detailObj
    const { adminKey } = getStore('admininfo', 'session') || {}
    this.state = {
      list,
      basePic,
      adminKey
    }
    this.handleEdit = this.handleEdit.bind(this)
    this.handleShowEdit = this.handleShowEdit.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleDetail = this.handleDetail.bind(this)
    this.handleSelectLeave = this.handleSelectLeave.bind(this)
  }
  handleDetail({ jifenId }) {
    // console.log(record)
    const { adminKey } = this.state
    get('/api/queryJifenDetais', { adminKey, jifenId: jifenId }).then(({ code, data: replyData }) => {
      // console.log(replyData)
      if (code === '9999') {
        const { list = [], basePic } = replyData
        this.setState({
          list,
        })
      }
    })
  }
  handleSelectLeave() {
    const { productId, keyword = '' } = this.state
    if (!productId && keyword.length > 0) {
      this.setState({
        keyword: '',
        productList: []
      })
    }
  }
  handleShowEdit(record) {

    if (this.props.detailParentObj.status === 'Y') return
    this.setState({
      editRecord: record
    })
  }
  handleEdit() {
    const { productId, productList, editNumber, editRecord, adminKey } = this.state
    const { onDetail, detailParentObj } = this.props
    const product = productList.filter(({ recordId }) => recordId === productId)[0]


    processRequest(
      get('/api/modifyJifenDetail', { adminKey, jifenDetailId: editRecord.recordId, number: editNumber, productId }),
      data => {
        this.setState({
          productList: [],
          editRecord: {}
        }, () => {
          this.handleDetail(editRecord)
        })

        console.log(data)
      }
    )
    // /api/modifyJifenDetail
    // /api/modifyJifenDetail 修改积分明细
    // String adminKey,
    // Long jifenDetailId,
    // Integer number,
    // Long productId

  }
  handleSearch(keyword) {
    // console.log(val)
    const that = this
    this.setState({
      keyword,
      productList: []
    }, () => {
      clearTimeout(this.time)
      this.time = setTimeout(() => that.fetchProduct(), 150)
    })
  }
  fetchProduct() {
    let { keyword } = this.state
    if (!keyword) {
      this.setState({
        productList: []
      })
      return
    }
    keyword = this.keywordFilter(keyword)

    processRequest(
      get('/api/queryProduct', { keyword }),
      data => {
        // console.log(data)
        const { result } = data
        this.setState({
          productList: result,
        })
      }
    )
  }
  keywordFilter(word) {
    return word.toLowerCase().replace('pll', 'pii').replace('pl', 'pi')
  }
  componentDidMount() {
    this.fetchProduct()
  }
  render() {
    const { visible, onCancel, detailParentObj} = this.props
    // console.log(detailObj)
    const { list = [], basePic, editRecord = {}, editJXH, editNumber, productList = [], productId } = this.state
console.log(detailParentObj)
    const { status } = detailParentObj
    return (
      <Modal
          visible={ visible }
          title={ `记录明细`  }
          okText= '确定'
          cancelText= '取消'
          onCancel={ onCancel }
          onOk={ onCancel }
        >
        <Row type='flex' justify='space-between' style={{ fontWeight: '700', marginBottom: '.2rem' }}>
          <Col span={5}>商品名</Col>
          <Col span={5} style={{ textAlign: 'center' }} >商品图</Col>
          <Col span={4} style={{ textAlign: 'center' }} >订单号</Col>
          <Col span={3} style={{ textAlign: 'center' }} >数量</Col>
          <Col span={4} style={{ textAlign: 'center' }} >总积分</Col>
          <Col span={3}></Col>
        </Row>
        {
          list.length > 0 && list.map(o => {
            const { recordId, productName, number, pic, total, juanxinHao } = o

            const isEdit = editRecord && editRecord.recordId === recordId

            return (
              <Row type='flex' justify='around' style={{ fontSize: '.12rem' }}>
                <Col span={5} style={{ cursor: 'pointer' }}  onClick={ () => this.handleShowEdit(o) }>
                  {
                    isEdit ? (
                      <Select onSearch={ this.handleSearch } onBlur={ this.handleSelectLeave } value={ productId } style={{ width: '100%', border: '1px solid #888', position: 'relative', top: '0.02rem' }} onChange={ val => this.setState({ productId: val }) } showSearch placeholder='请选择产品' filterOption={ this.handleFilterSelect }  >
                        {
                          productList.map(o => (
                            <Option value={ o.recordId } key={ o.name } data-val={ o }>{ o.name.replace('3M ENVISION', '') }</Option>
                          ))
                        }
                      </Select>
                    ) : (
                      <div>
                        { productName }
                        {
                          status === 'N' && (
                            <img src={ require('img/edit.png') } style={{ width: '14px', marginLeft: '6px' }} />
                          )
                        }
                      </div>
                    )
                  }
                  </Col>
                <Col span={5} style={{ textAlign: 'center' }}>
                  <a href={ `${ basePic }${ pic }` } target='_blank'>
                    <img src={ `${ basePic }${ pic }` } style={{ height: '50px' }} />
                  </a>
                </Col>
                <Col span={4} style={{ textAlign: 'center' }} >
                  {//<input style={{ border: '1px solid #888', width: '100%', margin: '0 5px' }} value={ editJXH || juanxinHao } onChange={ ({ currentTarget }) => this.setState({ editJXH: currentTarget.value }) } />
                    isEdit ? juanxinHao : juanxinHao
                  }
                </Col>
                <Col span={3} style={{ textAlign: 'center' }} >
                  {
                    isEdit ? (
                      <input style={{ border: '1px solid #888', width: '100%', margin: '0 10px', height:'.32rem', paddingLeft: '.1rem' }} value={ editNumber || number } onChange={ ({ currentTarget }) => this.setState({ editNumber: currentTarget.value }) } />
                    ) : number
                  }
                </Col>
                <Col span={4} style={{ textAlign: 'center' }} >{ total }</Col>
                <Col span={3}>
                  {
                    isEdit && (
                      <Button onClick={ this.handleEdit } style={{ textAlign: 'center', fontSize: '12px' }} size='small' type='primary'>更新</Button>
                    )
                  }

                </Col>
              </Row>
            )
          })
        }
      </Modal>
    )
  }
}

export default StoreForm

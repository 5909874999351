import React from 'react'
import ReactDOM from 'react-dom';
import {
  Tag,
  Popconfirm,
  message
} from 'antd'

function cancel(e) {
  console.log(e, 8);
}

function getStatus(status) {
  if (status == 'N') return '未审核';
  else if (status == 'F') return '审核失败';
  else if (status == 'Y') return '审核成功';
}

function setDOM(e, record, onOperate) {
  setTimeout(() => {
    var zjhtest = document.querySelectorAll('#zjhtest .ant-popover-buttons')
    zjhtest.forEach((item) => {
      item.addEventListener('click', (e) => {
        if (e.target.innerText == '取消') {
          onOperate(record, 'QX')
        } else if (e.target.innerText == '失败') {
          onOperate(record, 'SB')
        } else if (e.target.innerText == '通过') {
          onOperate(record, 'TG')
        }
      }, false)
      ReactDOM.findDOMNode(item).innerHTML =
        '<button type="button" class="ant-btn ant-btn-sm"><span>取消</span></button><button type="button" class="ant-btn ant-btn-sm"><span>失败</span></button><button type="button" class="ant-btn  ant-btn-primary  ant-btn-sm"><span>通过</span></button>'
    })
  })

}
export default ({
  onUpdate,
  onDel,
  onOperate,
  onDetail
}) => ([{
  title: 'ID',
  dataIndex: 'recordId',
  key: 'ID',
},
{
  title: '加工商公司名',
  dataIndex: 'jiagongshangName',
  key: 'jiagongshangName'
},
{
  title: '加工商员工',
  dataIndex: 'memberName',
  key: 'memberName'
},
{
  title: '类型',
  dataIndex: 'type',
  key: 'type'
},
{
  title: '积分',
  dataIndex: 'total',
  key: 'total'
},
{
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  render: (t, {
    status
  }) => {
    return getStatus(status)
  }
},
// {
//   title: '采购单',
//   dataIndex: 'pic',
//   key: 'pic',
//   render: (text, { pic }) => {
//     if (!pic) return '-'
//     const url = `http://commontemp.oss-cn-shanghai.aliyuncs.com/${ pic }`
//     return (
//       <a href={ url } target='_blank'>
//         <img src={ url } width='150px' alt='莫慌，这个测试的图片' />
//       </a>
//     )
//   }
// },
{
  title: '',
  key: 'action',
  render: (text, record) => {
    const {
      status
    } = record
    return ( <
        span >
        <
        a onClick = {
          () => onDetail(record)
        }
        style = {
          {
            marginRight: '.12rem'
          }
        } > 查看明细 < /a> {
        status === 'N' && ( <
          Popconfirm id = "zjhtest"
          title = "是否确认操作?"
          okText = "审核通过"
          cancelText = "审核失败" >
          <
          a href = "#"
          onClick = {
            (e) => setDOM(e, record, onOperate)
          } > 审核 < /a> < /
          Popconfirm >
        )
      } <
      /span>
  )
}
}])

import React from 'react'
import { browserHistory } from 'react-router'
import { Flex, Picker, Toast  } from 'antd-mobile'
import { Select } from 'antd'
import TextInput, { iptBlur, iptFocus } from 'component/text-input'
import SmsCode from 'component/sms-code'
import { get, setStore, getStore} from 'mulan-lib'

import { processRequest } from 'lib'
const Option = Select.Option

const inputStyle = {
  position: 'relative',
  top: '1.05rem',
  left: '.8rem',
  fontSize: '.15rem',
  color: '#333',
  height: '.3rem',
  lineHeight: '.3rem',
  display: 'block',
  marginBottom: '.33rem',
  width: '2.6rem'
}

const smsStyle = {
  width: '0.85rem',
  height: '0.2rem',
  color: '#fff',
  fontSize: '0.1rem',
  lineHeight: '0.2rem',
  position: 'absolute',
  right: '0',
  top: '0.05rem',
  textAlign: 'center',
  border: 0,
  fontWeight: '700',
}
class Login extends React.Component {
  constructor(props) {
    super(props)
    const { location } = props
    const { query } = location
    const { debug } = query
    const { api_u_key = ''} = getStore('userinfo','session') || {}

    this.state = {
      // companyList: companyList.map(o => ({
      //   label: o,
      //   value: o
      // })),
      api_u_key,
      companyList: [],
      debug,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSetCode = this.handleSetCode.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleFilterSelect = this.handleFilterSelect.bind(this)
    this.handleSelectLeave = this.handleSelectLeave.bind(this)

  }
  fetchJGS() {
    const { keyword } = this.state
    // console.log(keyword)
    if (!keyword) {
      // console.log('clear')
      this.setState({
        companyList: []
      })
      return
    }

    processRequest(
      get('/api/queryjiagongshang', { keyword }),
      data => {
        console.log(data)
        const { result } = data
        this.setState({
          companyList: result
        })
      }
    )
  }
  componentDidMount() {
    this.fetchJGS()
    setTimeout(() => {
      const el = document.querySelector('.ant-select-search__field')
      el.addEventListener('focus', iptFocus, false)
      el.addEventListener('blur', iptBlur, false)
    }, 200)
  }
  handleFilterSelect(word, option) {
    if (word.length > 0) {
      const { props } = option
      const { children } = props
      return children.indexOf(word) >= 0
    }
    return true
  }
  handleInputChange({ currentTarget, val }, key) {
    console.log(val, key)
    const o = {}
    o[key] = val || currentTarget.value || ''
    this.setState(o)
  }
  handleSetCode(code) {
    this.setState({
      code
    })
  }
  handleSubmit() {
    // console.log(this.state)
    const { name, phone, code, companyCode, api_u_key, companyCodeChoosed } = this.state
    console.log("this is api_u_key of handleSubmit of login")
    console.log(this.state)
    if (!name) {
      Toast.fail('请填写姓名')
      return
    }
    if (!phone) {
      Toast.fail('请填写手机')
      return
    }
    if (!code) {
      Toast.fail('请填写验证码')
      return
    }
    if (!companyCodeChoosed) {
      Toast.fail('请选择加工商')
      return
    }
    if (companyCodeChoosed.toLowerCase() !== companyCode.toLowerCase()) {
      Toast.fail('请输入正确的公司编码')
      return
    }
    Toast.loading('注册中...')
    processRequest(
      get('/api/bindJiagong', {
                                name,
                                jiagongshangCode: companyCodeChoosed,
                                mobile: phone,
                                code,
                                api_u_key,
                              }),
      data => {
        Toast.hide()
        window.location.replace('/')
      }
    )

  }
  handleSearch(keyword) {
    // console.log(val)
    const that = this
    this.setState({
      keyword,
      companyList: []
    }, () => {
      clearTimeout(this.time)
      this.time = setTimeout(() => that.fetchJGS(), 150)
    })
  }
  handleSelectLeave() {
    const { companyCodeChoosed, keyword = '' } = this.state
    if (!companyCodeChoosed && keyword.length > 0) {
      this.setState({
        keyword: '',
        companyList: []
      })
    }
  }
  render() {
    const { debug = false, name, phone, company, companyCode, companyList = [], code } = this.state
    // console.log(companyList)
    return (
      <Flex align='center' justify='center' style={{ height: '100%' }}>
        <div style={{
               height: '5rem',
               width: '100%',
               backgroundImage: `url(${ require('img/login/bg.png') })`,
               backgroundSize: '100% 100%',
               position: 'relative'
             }}>
          <TextInput onChange={ e => this.handleInputChange(e, 'name') } value={ name } style={ inputStyle } placeholder='请输入姓名' />
          <TextInput type='tel' onChange={ e => this.handleInputChange(e, 'phone') } value={ phone } style={ inputStyle } placeholder='请输入手机号' maxLength='11' />
          <Flex style={ { ...inputStyle, width: '2.65rem' } } justify='between'>
            <TextInput onChange={ e => this.handleInputChange(e, 'code') } value={ code } style={{ width: '1.5rem' }}  placeholder='请输入验证码' />
            <SmsCode style={ smsStyle } phone={ phone } debug={ debug } handleSetCode={ this.handleSetCode } />
          </Flex>
          <div style={ inputStyle }>
            <Select notFoundContent={
              <div style={{ textAlign: 'center', padding: '.2rem 0' }}>
                <img src={ require('img/empty.png') } style={{ width: '.5rem' }} />
                <div style={{ fontSize: '.12rem' }}>请输入公司名称</div>
              </div>
            } onSearch={ this.handleSearch } onBlur={ this.handleSelectLeave } style={{ width: '100%', border: 0 }} onChange={ val => this.handleInputChange({ val }, 'companyCodeChoosed') } showSearch placeholder='请输入加工商名称'  filterOption={ this.handleFilterSelect } >
              {
                companyList.map(o => (
                  <Option value={ o.number } key={ `${ o.name }${ o.level }` }>{ o.name }</Option>
                ))
              }
            </Select>
          </div>
          <TextInput value={ companyCode }  onChange={ e => this.handleInputChange(e, 'companyCode') } style={ inputStyle } placeholder='请输入公司编号' />
          <div style={{
            width:'2.05rem',
            height: '.3rem',
            position: 'relative',
            top: '1.47rem',
            left: '.87rem',
          }} onClick={ this.handleSubmit } />

        </div>
      </Flex>
    )
  }
}
// <Picker data={ companyList } onOk={ val => this.handleInputChange({ val: val[0] }, 'company') } cols={ 1 } >
//   <span id='xuanyan' style={{ fontSize: '.15rem', color: company && company && '#333' || '#a0a0a0' }}>{ company && company || '请选择加工商公司' }</span>
// </Picker>

export default Login

// <TextInput onChange={ e => this.handleInputChange(e, 'company') }  style={ inputStyle } placeholder='请输入加工商公司' />

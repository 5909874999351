/*
 * @Description:
 * @Autor: liang
 * @Date: 2020-04-10 14:13:28
 * @LastEditors: liang
 * @LastEditTime: 2020-04-11 09:50:03
 */
import React from "react";
import { Router, Route, hashHistory,browserHistory, IndexRoute } from "react-router";
import App from "./pages";
import Root from "./root";
import Login from "pages/login";
import Detail from "pages/detail";
import Channel from "pages/channel";
import Store from "pages/store";
import List from "pages/store/list";
import Record from "pages/store/record";
import Message from "pages/message";
import Add from "pages/add";
import Profile from "pages/profile";

import AdminLayout from "admin/layout";
import AdminLogin from "admin/login";
import AdminEmployee from "admin/employee";
import AdminFabricator from "admin/fabricator";
import AdminDealer from "admin/dealer";
import AdminJfrecord from "admin/jfrecord";
import AdminBanner from "admin/banner";
import AdminMessage from "admin/message";
import AdminJFStore from "./admin/jf-store/list";
import AdminJFStoreRecords from "./admin/jf-store/records";
// 路由配置
const Routes = () => (
  <Router history={browserHistory}>
    <Route path="/" component={Root}>
      <IndexRoute component={App} />
      <Route path="login" component={Login} />
      <Route path="detail" component={Detail} />
      <Route path="profile" component={Profile} />
      <Route path="channel" component={Channel} />
      <Route path="msg" component={Message} />
      <Route path="store" component={Store} />
      <Route path="store/list" component={List} />
      <Route path="store/record" component={Record} />
      <Route path="add/:JXSCode" component={Add} />
    </Route>
    <Route path="/admin" component={AdminLayout}>
      <IndexRoute component={AdminEmployee} />
      <Route path="company">
        <Route path="fabricator" component={AdminFabricator} />
        <Route path="dealer" component={AdminDealer} />
      </Route>
      <Route path="jfrecord" component={AdminJfrecord} />
      <Route path="banner" component={AdminBanner} />
      <Route path="jf-store" component={AdminJFStore} />
      <Route path="message" component={AdminMessage} />
      <Route path="jf-store/records" component={AdminJFStoreRecords} />
    </Route>
    <Route path="/admin/login" component={AdminLogin} />
  </Router>
);
export default Routes;

// <Route path='company'>
//   <Route path='class-poster' component={ AdminDataClassPoster } />
//   <Route path='class-poster-student' component={ AdminDataClassPosterStudent } />
// </Route>

import React from 'react'
import { Tag, Popconfirm, message } from 'antd'

function cancel(e) {
  console.log(e);
}


export default ({ onUpdate, onDel, onOperate }) => ([
  {
    title: 'ID',
    dataIndex: 'recordId',
    key: 'ID',
  },

  {
    title: '推荐位',
    dataIndex: 'logoUri',
    key: 'logoUri',
    render: (text, { logoUri, url }) => {
      if (!logoUri) return '-'
      return (
        <a href={ url } target='_blank'>
          <img src={ `http://3m-point.oss-cn-shanghai.aliyuncs.com/${ logoUri }` } width='150px' />
        </a>
      )
    }
  },

  {
    title: '权重',
    dataIndex: 'sortId',
    key: 'sortId'
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    render: (text, { status }) => {
      return (<span>{ status === 'N' ? '禁用' : '启用' }</span>)
    }
  },
  {
    title: '',
    key: 'action',
    render: (text, record) => (
      <span>
        <Popconfirm title="是否确认操作?" onConfirm={ () => onOperate(record) } onCancel={ cancel } okText="是" cancelText="否">
          <a href="#">{ record.status === 'N' ? '启用' : '禁用' }</a>
        </Popconfirm>
        <a href='#' style={{ marginRight: '12px', marginLeft: '12px' }} onClick={ () => onUpdate(record) }>编辑</a>
      </span>
    )
  }
])

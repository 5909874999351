/*
 * @Description:
 * @Autor: liang
 * @Date: 2019-09-30 15:39:44
 * @LastEditors: liang
 * @LastEditTime: 2020-04-11 09:49:50
 */
import React, { Component } from "react";
import { Flex } from "antd-mobile";
import addVconsole from "utils/addvconsole";

class Root extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    addVconsole(); //唤起控制台
    document.body.style.minHeight = "5.95rem";
  }

  render() {
    const { children } = this.props;

    return (
      <div
        className="root h5-root"
        style={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${require("img/bg.png")})`,
          backgroundSize: "150%",
          backgroundPosition: "-1.4rem -1.3rem",
        }}
      >
        {children}
      </div>
    );
  }
}

export default Root;

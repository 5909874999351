import React from 'react'
import { Carousel, Flex, Progress, Picker, Toast } from 'antd-mobile'
import { browserHistory } from 'react-router'
import Btn from 'component/btn'
import Home from 'component/home'
import { Select } from 'antd'
import { get, getStore } from 'mulan-lib'
import { processRequest } from 'lib'
import { iptBlur, iptFocus } from 'component/text-input'
import './channel.css'
const Option = Select.Option

class Detail extends React.Component {
  constructor(props) {
    super(props)
    const companyInfo = getStore('companyinfo', 'session') || {}
    const { api_u_key = '' } = getStore('userinfo', 'session') || {}
    this.state = {
      companyList: [],
      companyInfo,
      api_u_key
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleFilterSelect = this.handleFilterSelect.bind(this)
    this.handleSelectLeave = this.handleSelectLeave.bind(this)
  }
  fetchJXS() {
    const { keyword } = this.state
    console.log(keyword)
    if (!keyword) {
      this.setState({
        companyList: []
      })
      return
    }

    processRequest(
      get('/api/queryJingxiaoshang', { keyword }),
      data => {
        console.log(data)
        const { result } = data
        this.setState({
          companyList: result,
        })
      }
    )
  }
  handleFilterSelect(word, option) {
    // console.log(word, option)
    if (word.length > 0) {
      const { props } = option
      const { children } = props
      return children.indexOf(word) >= 0
    }
    return true
  }
  handleInputChange({ currentTarget, val }, key) {
    const o = {}
    o[key] = val || currentTarget.value || ''
    this.setState(o)
  }
  handleSubmit() {
    // console.log(this.state)
    const { companyCode = '' } = this.state
    if (!companyCode) {
      Toast.fail('请选择经销商')
      return
    }
    // browserHistory.push(`/add/${ companyCode }`)
    window.location.replace(`/add/${ companyCode }`)
  }
  componentDidMount() {
    this.fetchJXS()
    setTimeout(() => {
      const el = document.querySelector('.ant-select-search__field')
      el.addEventListener('focus', iptFocus, false)
      el.addEventListener('blur', iptBlur, false)
    }, 200)
  }
  handleSearch(keyword) {
    // console.log(val)
    const that = this
    this.setState({
      keyword,
      companyList: []
    }, () => {
      clearTimeout(this.time)
      this.time = setTimeout(() => that.fetchJXS(), 150)
    })
  }
  handleSelectLeave() {
    const { companyCode, keyword = '' } = this.state
    if (!companyCode && keyword.length > 0) {
      this.setState({
        keyword: '',
        companyList: []
      })
    }
  }
  render() {
    const { companyList = [], company = '' } = this.state

    return (
      <div style={{ color: '#fff', textAlign: 'center', paddingTop: '1rem' }}>
        <img src={ require('img/channel/banner.png') }  />
        <div style={{
              width: '2.66rem',
              height: '.35rem',
              lineHeight: '.25rem',
              marginTop: '.43rem',
              marginBottom: '2.17rem',
              marginLeft: 'calc(50% - 1.33rem)',
              backgroundImage: `url(${ require('img/channel/search.png') })`,
              backgroundSize: '100% 100%',
              transformOrigin: 'center center',
              transform: 'scale(1.2)',
             }}>
          <Select notFoundContent={
            <div style={{ textAlign: 'center', padding: '.2rem 0' }}>
              <img src={ require('img/empty.png') } style={{ width: '.5rem' }} />
              <div style={{ fontSize: '.12rem' }}>请输入公司名称</div>
            </div>
          } onSearch={ this.handleSearch } onBlur={ this.handleSelectLeave } style={{ width: '100%', border: 0, paddingLeft: '12%' }} onChange={ val => this.handleInputChange({ val }, 'companyCode') } showSearch placeholder='请填写经销商名称' filterOption={ this.handleFilterSelect }  >
            {
              companyList.map(o => (
                <Option value={ o.number } key={ o.name }>{ o.name }</Option>
              ))
            }
          </Select>
        </div>

        <Btn style={{
          width: '2.05rem',
        }} onClick={ this.handleSubmit } >
          确定
        </Btn>
        <Home />
      </div>
    )
  }
}

export default Detail

// <div style={{
//       width: '2.66rem',
//       height: '.35rem',
//       lineHeight: '.25rem',
//       marginTop: '.43rem',
//       marginBottom: '2.17rem',
//       marginLeft: 'calc(50% - 1.33rem)',
//       backgroundImage: `url(${ require('img/channel/search.png') })`,
//       backgroundSize: '100% 100%'
//      }}
// >
//   <Picker data={ companyList } onOk={ val => this.handleInputChange({ val: val[0] }, 'company') } cols={ 1 } >
//     <span id='xuanyan' style={{ fontSize: '.15rem', color: company && company && '#333' || '#a0a0a0' }}>{ company && company || '请选择经销商' }</span>
//   </Picker>
// </div>

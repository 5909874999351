import React from 'react'
import { Modal, Form, Input, Radio, Message } from 'antd'
import { Flex } from 'antd-mobile'
import Upload from 'rc-upload'
import { baseApiUrl } from 'config'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
  colon: true
};

class StoreForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentImgKey: '',
      currentImg: ''
    }

    const _t = this
    this.handleCreate = this.handleCreate.bind(this)
    this.uploaderProps = {
      action: `${ baseApiUrl }/common/uploadPic`,
      data: { a: 1, b: 2 },
      headers: {},
      multiple: false,
      beforeUpload(file) { console.log('beforeUpload', file.name) },
      onStart: (file) => {
        Message.loading('上传中', 0)
      },
      onSuccess({ code, data, msg }) {
        // console.log('onSuccess', data)
        if (code === '9999') {
          const { url, fileKey } = data
          const { currentImg } = _t.state

          console.log(url)
          _t.setState({
            currentImg: url,
            currentImgKey: fileKey,
            canSubmit: true,
            updatePic: true
          })

          Message.destroy()
        } else {
          console.log('upload fail')

          Message.destroy()
          Message.info(msg, 2)
        }
      },
      onProgress(step, file) { console.log('onProgress', Math.round(step.percent), file.name) },
      onError(err) { console.log('onError', err) },
    };
  }
  handleCreate() {
    const { onCreate } = this.props
    this.setState({
      currentImgKey: '',
      currentImg: '',
      canSubmit: false,
      updatePic: false
    })
    onCreate()
  }
  render() {
    const {
        visible, onCancel, onCreate, form,
        teseObj = {}, basePic = ''
    } = this.props
    const { recordId = '', logoUri = '', logoUrl = '' } = teseObj

    let { currentImg = '', currentImgKey = '', updatePic = false } = this.state

    if (logoUri.length > 0 && !updatePic) {
      currentImg = `http://3m-point.oss-cn-shanghai.aliyuncs.com/${ logoUri }`
      currentImgKey = logoUri
    }

    const { getFieldDecorator } = form

    return (
      <Modal
          visible={ visible }
          title={ `${ recordId ? '编辑' : '新增' }Banner`  }
          okText= '确定'
          cancelText= '取消'
          onCancel={ onCancel }
          onOk={ this.handleCreate }
        >
          <Form layout="vertical">

            <Form.Item label="Banner" { ...formItemLayout }>
              {getFieldDecorator('logoUri', {

                initialValue: currentImgKey || '',
                rules: [{ required: true, message: '请上传Banner!' }],
              })(
                <Upload {...this.uploaderProps}
                        component="div"
                        style={{
                          display: 'block',
                          position: 'relative',
                          width: '100%',
                          height: '1.2rem',
                          backgroundColor: '#fafafa',
                          border: '.005rem dashed #999',
                          marginBottom: '.25rem',
                        }}
                >
                  <Flex onTouchStart={
                    e => {
                      e.preventDefault()
                      document.querySelector('input').click()
                    }
                  } justify='center' align='center' style={{ height: '100%' }} wrap='wrap'>
                    {
                      currentImg.length > 0 ? (
                        <img src={ currentImg } style={{ height: '100%' }} />
                      ) : (
                        <div style={{ textAlign: 'center' }} >
                          <img src={require('img/xj.png')} style={{
                            width: '.35rem',
                            lineHeight: '.3rem',
                            marginBottom: '.1rem'
                          }} />
                          <div style={{
                            lineHeight: '.16rem',
                            position: 'relative',
                            top: 0,
                            fontSize: '.16rem',
                            color: '#666'
                          }} >上传照片</div>
                        </div>
                      )
                    }
                  </Flex>
                  <div style={{ fontSize: '.12rem', color: '#999', marginTop: '.05rem' }}>建议尺寸: 645 * 417</div>
                </Upload>
              )}
            </Form.Item>
            <Form.Item label="跳转链接" { ...formItemLayout }>
              {getFieldDecorator('url', {
                initialValue: teseObj.url || '',

                rules: [{ required: true, message: '请输入跳转链接!' }],
              })(
                <Input placeholder='请输入以 http:// 或 https:// 开头的链接' />
              )}
            </Form.Item>
            <Form.Item label="排序权重" { ...formItemLayout }>
              {getFieldDecorator('sortId', {
                initialValue: teseObj.sortId || '',
                rules: [{ required: true, message: '请输入排序权重!' }],
              })(
                <Input placeholder='请输入排序权重，数字越大越靠前' type="number" />
              )}
            </Form.Item>
            <Form.Item label="" { ...formItemLayout }>
              {getFieldDecorator('recordId', {
                initialValue: teseObj.recordId || '',
              })(
                <Input type='hidden' />
              )}
            </Form.Item>
          </Form>
        </Modal>
    )
  }
}

export default Form.create()(StoreForm)

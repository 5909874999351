/*
 * @Description:
 * @Autor: liang
 * @Date: 2020-04-10 15:21:40
 * @LastEditors: liang
 * @LastEditTime: 2020-04-11 09:49:43
 */
import { getStore } from "mulan-lib";

export const gamePart = 3;

// export const domain = "3m-h5.adleading.com";
// export const apiDomain = "openapi.adleading.com";
// export const baseUrl = `https://${domain}`;

// export const baseApiUrl = `https://${apiDomain}/threem`;

// 新
export const domain    = "csd.ittabc.com";

export const apiDomain = "csd.ittabc.com";

export const baseUrl    = `http://${domain}`;

export const baseApiUrl = `http://${apiDomain}:8083`;

export const appid = "wxbef77f4a0d2ad7cd";
// export const appid = 'wxbef77f4a0d2ad7cd'
// 开发者ID(AppID)   wxbef77f4a0d2ad7cd
// Appsecret dfb513ded0b1cffde298ccae74c253ef

export const setShare = (props) => {
  const { link = "", content, title } = props;
  setTimeout(() => {
    const shareInfo = {
      content: content,
      title: title,
      url: link,
      icon: "",
      cb: function() {},
    };
    if (window._wx) {
      window._wx.init(shareInfo);
    }
    console.log("set share success");
  }, 500);
};

import React from 'react'
import {
    Carousel,
    Flex,
    Progress,
    Toast,
    Badge
} from 'antd-mobile'
import {
    browserHistory
} from 'react-router'
import Mask from 'component/mask'
import PopPic from 'component/pop-pic'
import {
    get,
    setStore,
    getStore
} from 'mulan-lib'

import {
    processRequest
} from 'lib'
import {
    wx_auth
} from 'config/request'
import addVconsole from "../lib/utils/addvconsole";
import axios from "axios";

const cardStyle = {
    width: '.75rem',
    boxShadow: '0px 3px 5px 0px rgba(172,142,142,0.23)',
    borderRadius: '.05rem'
}
const card2Style = {
    width: '1rem',
    borderRadius: '.05rem'
}
// const card2Style = { width: '48%',  borderRadius: '.05rem' }

const stopScroll = e => {
    e.preventDefault()
}


class Main extends React.Component {
    constructor(props) {
        super(props)
        // addVconsole(true)
        const {
            location
        } = props
        const {
            query
        } = location
        const {
            code: openCode
        } = query

        const {
            api_u_key,
        } = getStore('userinfo', 'session') || {}
        const headImgUrl = (getStore('userinfo', 'session') && getStore('userinfo', 'session').headImgUrl) || ''
        const nickname = (getStore('userinfo', 'session') && getStore('userinfo', 'session').nickname) || ''
        this.state = {
            api_u_key: api_u_key || '',
            openCode: openCode || '',
            nickname: nickname || '',
            headImgUrl: headImgUrl || '',
            bannerShow: false
        }
        console.log(this.state)
        this.handleRuleClick = this.handleRuleClick.bind(this)
        this.fetchZhengshu = this.fetchZhengshu.bind(this)
    }

    // 获取API_U_KEY
    fetchUser(cb) {
        const {
            openCode,
        } = this.state
        console.log('openCode from fetchUser')
        console.log(openCode)

        processRequest(
            get('/api/loginByWeixinMpCode', {
                code: openCode
            }),
            data => {
                // const data = { api_u_key: 'pzc116' }
                const {
                    nickName, headImgUrl
                } = data.data
                data = {openCode, ...data, nickname: nickName, headImgUrl}
                console.log('data from fetchUser')
                console.log(data)
                setStore('userinfo', data, 'session')
                this.setState(data, cb)
                console.log('state from fetchUser')
                console.log(this.state)
            },
            (msg, code) => {
                if (code === '40001' && msg === '请先关注公众号') {
                    Mask(<div style={
                        {
                            position: 'absolute',
                            zIndex: 99,
                            width: '2rem',
                            left: 'calc(50% - 1rem)',
                            top: 'calc(50% - 1rem)',
                        }
                    }>
                        <img src='https://mom-bullet.oss-cn-shanghai.aliyuncs.com/3M/qr.jpeg'
                             style={
                                 {
                                     border: '1px solid #000',
                                     width: '100%'
                                 }
                             }
                        />
                        <div style={
                            {
                                fontSize: '.2rem',
                                textAlign: 'center',
                                color: '#fff',
                                fontWeight: 700,
                                marginTop: '.1rem',
                            }
                        }> 长按扫描二维码关注
                        </div>
                    </div>, {
                        maskStyle: {
                            opacity: .8
                        },
                        maskClosable: false
                    })
                } else {
                    Toast.fail(msg)
                }
            }
        )
    }

// 获取加工商信息
    fetchJGSInfo() {
        const {
            api_u_key
        } = this.state
        console.log('this is api_u_key from fetchJGSInfo before send')
        console.log(api_u_key)
        processRequest(
            get('/api/getLoginMemberInfo', {
                api_u_key
            }),
            data => {
                console.log(888, data)
                const {
                    cname
                } = data
                this.setState({
                    companyInfo: data,
                    cname
                }, this.fetchZhengshu)
                console.log('state from fetchJGSInfo')
                console.log(this.state)
                setStore('companyinfo', data, 'session')
            }
        )
    }

// 判断是否绑定过加工商
    fetchUserIsReg() {
        console.log('this is fetchUserIsReg')
        const {
            api_u_key
        } = this.state
        console.log('this is api_u_key of fetchUserIsReg before send')
        console.log(api_u_key)
        processRequest(
            get('/api/isBindJiagong', {
                api_u_key
            }),
            data => {
                const {
                    isBind
                } = data
                console.log('isBind')
                console.log(isBind)
                if (!isBind) {
                    browserHistory.push('/login')
                } else {
                    this.fetchJGSInfo()
                    this.fetchXiaoHongDian()
                }
            }
        )
    }

    handleRuleClick() {
        const style = {
            position: 'absolute',
            top: '1rem'
        }
        document.body.addEventListener('touchmove', stopScroll, {
            passive: false
        })
        this.setState({
            isShowMenu: false
        })
        Mask(< PopPic pic='rule/1'
                      suffix='png'
                      maxWidth='100%'
                      style={
                          style
                      }
                      onClose={
                          () => {
                              Mask(< PopPic pic='rule/2'
                                            suffix='png'
                                            maxWidth='100%'
                                            style={
                                                style
                                            }
                                            onClose={
                                                () => Mask(< PopPic pic='rule/3'
                                                                    maxWidth='100%'
                                                                    style={
                                                                        style
                                                                    }
                                                                    suffix='png'
                                                                    onClose={
                                                                        () => {
                                                                            document.body.removeEventListener('touchmove', stopScroll, {
                                                                                passive: false
                                                                            })
                                                                            this.setState({
                                                                                isShowMenu: true
                                                                            })
                                                                        }
                                                                    }
                                                />, {
                                                    maskStyle: {
                                                        opacity: '.7'
                                                    }
                                                })
                                            }
                              />, {
                                  maskStyle: {
                                      opacity: '.7'
                                  }
                              })
                          }
                      }
        />, {
            maskStyle: {
                opacity: '.7'
            }
        })
    }

    fetchBanner() {
        processRequest(
            get('/api/queryRecommon', {
                cate: '',
                type: '',
                from: 0,
                max: 999,
                status: 'Y'
            }),
            data => {
                const {
                    list
                } = data
                console.log(list)
                console.log('this is banner list')
                // const { list } = replyData
                this.setState({
                    bannerList: list,
                    bannerShow: true
                })
            }
        )
    }

    fetchZhengshu() {
        this.fetchJGS(c => {
            console.log(c)
            if (c.length > 0) {
                const {
                    recordId,
                    address = ''
                } = c[0]
                setStore('companyAddress', address, 'session')
                setStore('originCompany', c[0], 'session')
                processRequest(
                    get('/common/listPicture.xhtml', {
                        relateId: recordId,
                        tag: 'faq-detail',
                        from: 0,
                        max: 10
                    }),
                    data => {
                        const {
                            picBase,
                            picList
                        } = data
                        const detailPic = picList.map((o, i) => {
                            const {
                                url
                            } = o
                            return `${picBase}${url}`
                        })
                        this.setState({
                            detailPic,
                            showZhengshu: true,
                        })
                    }
                )

            }
        })
    }

    fetchJGS(cb) {
        const {
            cname
        } = this.state
        // console.log(keyword)
        if (!cname) {
            return
        }
        const keyword = cname
        processRequest(
            get('/api/queryjiagongshang', {
                keyword
            }),
            data => {
                console.log(data)
                const {
                    result
                } = data
                cb(result)
            }
        )
    }

    fetchXiaoHongDian() {
        const {
            api_u_key
        } = this.state
        console.log('this is api_u_key from fetchXiaoHongDian before send')
        console.log(api_u_key)
        processRequest(
            get('/api/hasXiaohongdian', {
                key: 'xhz-huifu',
                api_u_key
            }),
            data => {
                console.log(data)
                const {
                    hasXiaohongdian
                } = data
                this.setState({
                    hasXiaohongdian
                })
                console.log('state from fetchXiaoHongDian')
                console.log(this.state)
            }
        )
    }

    handle = () => {
        const url = encodeURI('http://csd.ittabc.com/agent.php')
        window.location = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx915e0f5f377da7ef&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
    }

    componentDidMount() {
        if (window.location.href != 'http://jf.ittabc.com/') {
            var o1 = window.location.href.split("=")
            var o2 = o1[1] ? o1[1].split("&") : ""
            var code = o2[0]
            console.log('this is code:')
            console.log(code)
            console.log('this is href:')
            console.log(window.location.href)
            this.setState({openCode: code})
            console.log('state from first')
            console.log(this.state)
            const {
                api_u_key
            } = this.state
            if (api_u_key) {
                this.fetchUserIsReg()
            } else {
                this.fetchUser(this.fetchUserIsReg)
            }
            this.fetchBanner()
            this.fetchIsCheck()
            setStore('userinfo',this.state,'session')
        } else {
            const userInfo = getStore('userinfo', 'session')
            console.log('this is userInfo')
            console.log(userInfo)
            if(!userInfo){
                this.handle()
            }else{
                this.state={...userInfo}
                console.log('state from second')
                console.log(this.state)
                const {
                    openCode,
                    api_u_key
                } = userInfo
                if (!openCode) {
                    this.handle()
                } else {
                    if (api_u_key) {
                        this.fetchUserIsReg()
                    } else {
                        this.fetchUser(this.fetchUserIsReg)
                    }
                    this.fetchBanner()
                    this.fetchIsCheck()
                }
            }
        }
        // const userInfo = getStore('userinfo', 'session')
        // if (userInfo) {
        //     this.state = {...userInfo}
        //     const {
        //         api_u_key
        //     } = userInfo
        //     if (api_u_key) {
        //         this.fetchUserIsReg()
        //     } else {
        //         this.fetchUser(this.fetchUserIsReg)
        //     }
        //     this.fetchBanner()
        //     this.fetchIsCheck()
        // }else{
        //     const dataSet={
        //         api_u_key:"2ef1379aa5454c47649697cbd7052c2c",
        //         openCode:"0610mrFa1dlCnA0ZDRHa124Nhc20mrFC",
        //         headImgUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLhN1GvF555suPdXyibVug0GYTykbKKPRyH5OicRV8eQaicvicxVboWcQSW4CqQ1bAD0FprFK5icGzFyeg/132",
        //         nickname:"二果",
        //         data:{
        //             headImgUrl:"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLhN1GvF555suPdXyibVug0GYTykbKKPRyH5OicRV8eQaicvicxVboWcQSW4CqQ1bAD0FprFK5icGzFyeg/132",
        //             nickname:"二果"
        //         }
        //     }
        //     setStore("userinfo",dataSet,"session")
        // }
    }

    fetchIsCheck() {
        const {
            api_u_key
        } = this.state
        const postData = {
            relateId: '001',
            type: 'store-check-switch',
        }
        get('/api/queryMiscData', postData).then(reply => {
            const {
                data = '0', recordId, code
            } = reply

            if (!code) {
                this.setState({
                    isCheck: data,
                })
            } else {
                this.setState({
                    isCheck: '1'
                })
            }
        })

    }

    handlePopZhengshu() {
        const {
            detailPic = [], showZhengshu = false
        } = this.state
        Mask(<PopPic pic='mcsrz'> {
            showZhengshu && (<Carousel autoplay={
                    false
                }
                                       infinite dotStyle={
                    {
                        bottom: '.2rem'
                    }
                }
                                       beforeChange={
                                           (from, to) => console.log(`slide from ${from} to ${to}`)
                                       }
                                       afterChange={
                                           index => console.log('slide to', index)
                                       }
                                       style={
                                           {
                                               height: '2.09rem',
                                               position: 'absolute',
                                               width: '2.97rem',
                                               left: 'calc(50% - 1.485rem)'
                                           }
                                       }>
                    {
                        detailPic.length > 0 && detailPic.map(o => (<
                                img src={
                                o
                            }
                                    onClick={
                                        () => {
                                            Mask(< PopPic src={
                                                o
                                            }
                                                          suffix='png'
                                                          maxWidth='100%'
                                                          style={
                                                              {
                                                                  width: '100%'
                                                              }
                                                          }
                                            />, {
                                                maskStyle: {
                                                    opacity: '.95'
                                                }
                                            })
                                        }
                                    }
                                    style={
                                        {
                                            width: '100%',
                                            minHeight: '2.09rem'
                                        }
                                    }
                            />
                        ))
                    } </Carousel>
            )
        }

        </PopPic>, {
            maskStyle: {
                opacity: '.85'
            }
        })
    }

    render() {
        const {
            companyInfo = {}, headImgUrl, bannerShow, bannerList, isShowMenu = true, isCheck = false, hasXiaohongdian
        } = this.state
        const {
            cname: companyName = '',
            level: companyLevel = '',
            name: companyLoginName = '',
            headPic: headPicImg = ''
        } = companyInfo

        return (<div style={
                {
                    color: '#fff',
                    padding: '.33rem .27rem'
                }
            }>

                <div style={
                    {
                        height: '2.09rem',
                        marginBottom: '.24rem',
                        position: 'relative'
                    }
                }> {
                    bannerShow && (<Carousel autoplay={
                            false
                        }
                                             infinite beforeChange={
                            (from, to) => console.log(`slide from ${from} to ${to}`)
                        }
                                             afterChange={
                                                 index => console.log('slide to', index)
                                             }
                                             style={
                                                 {
                                                     height: '2.09rem'
                                                 }
                                             }>
                            {
                                bannerList.length > 0 && (
                                    bannerList.map(o => {
                                        const basePic = 'https://commonperm.oss-cn-shanghai.aliyuncs.com/'
                                        return (<
                                                img src={
                                                `${basePic}${o.logoUri}`
                                            }
                                                    style={
                                                        {
                                                            width: '100%',
                                                            minHeight: '2.09rem'
                                                        }
                                                    }
                                                    onClick={
                                                        () => window.location.href = o.url
                                                    }
                                            />
                                        )
                                    })
                                )
                            } </Carousel>
                    )
                }

                </div>
                <Flex style={
                    {
                        padding: '.12rem .14rem',
                        color: '#333',
                        fontSize: '.12rem',
                        background: 'rgba(255,255,255,1)',
                        boxShadow: '0px 0px .19rem .03rem rgba(149,113,113,0.2)',
                        borderRadius: '.05rem',
                        marginBottom: '.24rem'
                    }
                }>
                    <div style={
                        {
                            width: '.64rem',
                            height: '.64rem',
                            borderRadius: '.32rem',
                            backgroundColor: '#eee',
                            marginRight: '.23rem'
                        }
                    }>
                        <img src={
                            headImgUrl ? headImgUrl : headPicImg
                        }
                             style={
                                 {
                                     width: '100%',
                                     borderRadius: '50%'
                                 }
                             }
                        /></div>
                    <div>
                        <div> 登陆姓名： {
                            companyLoginName
                        } </div>
                        <div> 公司名称： {
                            companyName
                        } </div>
                        <Flex justify='start'> 会员等级： <Progress percent={
                            40
                        }
                                                               position="normal"
                                                               unfilled={
                                                                   false
                                                               }
                                                               appearTransition style={
                            {
                                display: 'inline',
                                width: '.74rem',
                                borderRadius: '.5rem',
                                backgroundColor: '#ddd',
                                overflow: 'hidden',
                                marginRight: '.08rem'
                            }
                        }
                                                               barStyle={
                                                                   {
                                                                       border: 0,
                                                                       background: 'linear-gradient(224deg,rgba(241,66,66,1) 0%,rgba(245,133,133,1) 100%)',
                                                                   }
                                                               }
                        /> {companyLevel} </Flex></div>
                </Flex> {
                isShowMenu && (<div>
                        <Flex justify='between'
                              style={
                                  {
                                      marginBottom: '.1rem',
                                      overflow: 'initial'
                                  }
                              }>
                            <
                                img src={
                                require('img/main/sygz.jpg')
                            }
                                    style={
                                        cardStyle
                                    }
                                    onClick={
                                        this.handleRuleClick
                                    }
                            /> <
                            img src={
                            require('img/main/djjs.jpg')
                        }
                                style={
                                    cardStyle
                                }
                                onClick={
                                    () => Mask(< PopPic pic='hydj'/>)
                                }
                        /> <
                            img src={
                            require('img/main/mcsrz.jpg')
                        }
                                style={
                                    cardStyle
                                }
                                onClick={
                                    () => this.handlePopZhengshu()
                                }
                        /> <
                            img src={
                            require('img/main/grxx.jpg')
                        }
                                style={
                                    cardStyle
                                }
                                onClick={
                                    () => browserHistory.push('/profile')
                                }
                        /> </Flex> <Flex justify='between'
                                         style={
                                             {
                                                 overflow: 'initial'
                                             }
                                         }>
                        <
                            img src={
                            require('img/main/jflr2.png')
                        }
                                style={
                                    card2Style
                                }
                                onClick={
                                    () => browserHistory.push('/detail')
                                }
                        /> <Badge dot={
                        hasXiaohongdian
                    }
                                  style={
                                      {
                                          top: '-0.04rem',
                                          right: '-0.07rem',
                                          zIndex: 99,
                                          border: '3px solid #fff',
                                          width: '.12rem',
                                          height: '.12rem',
                                      }
                                  }>
                        <
                            img src={
                            require('img/main/lyb.png')
                        }
                                style={
                                    card2Style
                                }
                                onClick={
                                    () => browserHistory.push('/msg')
                                }
                        /> </Badge> <
                        img src={
                        require('img/main/jfsc2.png')
                    }
                            style={
                                card2Style
                            }
                            onClick={
                                () => {
                                    if (isCheck == 1) {
                                        Toast.fail('商城未开启')
                                        return
                                    } else {
                                        browserHistory.push('/store')
                                    }
                                }
                            }
                    /> </Flex></div>
                )
            }

            </div>
        )
    }
}

export default Main

// <img src={require('img/main/jflr2.png')} style={card2Style}

//      onClick={ () => browserHistory.push('/detail') } />
// <img src={ require('img/main/jfsc2.png') } style={ card2Style }
//      onClick={ () => {
//        if (isCheck == 1) {
//          Toast.fail('商城未开启')
//          return
//        } else {
//          browserHistory.push('/store')
//        }
//      } } />
// <img src={ require('img/main/lyb.png') } style={ card2Style }
//      onClick={ () => browserHistory.push('/msg') } />

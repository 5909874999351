import React from "react";

import {Carousel, Flex, Progress, Picker, Toast} from "antd-mobile";
import {browserHistory} from "react-router";
import Btn from "component/btn";
import TextInput, {iptBlur, iptFocus} from "component/text-input";
import Upload from "component/upload-m";
import Mask from "component/mask";
import PopPic from "component/pop-pic";
import Home from "component/home";
import {Select} from "antd";
import {getStore, get, send, setStore} from "mulan-lib";
import {processRequest} from "lib";
import DetailItem from "./DetailItem";
import addVconsole from "../lib/utils/addvconsole";

const Option = Select.Option;

var isIOS = function () {
    return /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
};

class Detail extends React.Component {
    constructor(props) {
        super(props);
        // addVconsole(true)
        const {location} = props;
        const {query} = location;
        const {debug} = query;
        const {api_u_key = ""} = getStore("userinfo", "session") || {};

        this.state = {
            api_u_key,
            productList: [],
            number: null,
            pushedProductList: [],
            show: false,
            debug,
            wx: window.wx,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleFilterSelect = this.handleFilterSelect.bind(this);
        this.handlePushProduct = this.handlePushProduct.bind(this);
        this.handleUploadItemPic = this.handleUploadItemPic.bind(this);
        this.handleUploadJHD = this.handleUploadJHD.bind(this);
        this.handleSelectLeave = this.handleSelectLeave.bind(this);
        this.handleSetJXH = this.handleSetJXH.bind(this);
    }

    handleInputChange({currentTarget, val}, key) {
        const o = {};
        o[key] = val || currentTarget.value || "";
        this.setState(o);
    }

    fetchProduct() {
        let {keyword} = this.state;
        if (!keyword) {
            this.setState({
                productList: [],
            });
            return;
        }
        keyword = this.keywordFilter(keyword);

        processRequest(get("/api/queryProduct", {keyword}), (data) => {
            // console.log(data)
            const {result} = data;
            this.setState({
                productList: result,
            });
        });
    }

    handleSubmit() {
        console.log(this.state);
        const {pushedProductList, jhdUrl: jinhuodanImg, api_u_key} = this.state;
        console.log('pushedProductList', pushedProductList)
        if (pushedProductList.filter(({picUrl}) => !picUrl).length > 0) {
            // Toast.fail("请上传产品图片");
            // return;
        }
        if (pushedProductList.filter(({dingdanHao}) => !dingdanHao).length > 0) {
            Toast.fail('请填写订单号')
            return
        }
        // if (!jinhuodanImg) {
        //   Toast.fail('请上传进货单')
        //   return
        // }
        console.log("postData!!!!!", {
            api_u_key,
            dataJson: JSON.stringify(
                pushedProductList.map(
                    ({recordId: productId, number, picUrl: img, dingdanHao}) => ({
                        productId,
                        number,
                        img,
                        dingdanHao
                    })
                )
            ),
        });
        processRequest(
            get(
                "/api/saveJifen",
                {
                    api_u_key,
                    dataJson: JSON.stringify(
                        pushedProductList.map(
                            ({recordId: productId, number, picUrl: img, dingdanHao}) => ({
                                productId,
                                number,
                                img: img ? img : '',
                                orderId: dingdanHao
                            })
                        )
                    ),
                },
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded;",
                    },
                }
            ),
            (data) => {
                // console.log(data)
                Mask(
                    <PopPic pic="jfsh" onClose={() => browserHistory.push("/detail")}/>
                );
            }
        );
        // browserHistory.push('/add')
    }

    handleSearch(keyword) {
        // console.log(val)
        const that = this;
        this.setState(
            {
                keyword,
                productList: [],
            },
            () => {
                clearTimeout(this.time);
                this.time = setTimeout(() => that.fetchProduct(), 150);
            }
        );
    }

    keywordFilter(word) {
        return word
            .toLowerCase()
            .replace("pll", "pii")
            .replace("pl", "pi");
    }

    configWx(callback) {
        const {debug = 0} = this.state;

        processRequest(
            get("/api/getJsSign", {
                url: isIOS() ? window.entryUrl : window.location.href.split("#")[0],
            }),
            (data) => {
                const {wx} = this.state;
                // console.log(wx)
                wx.config({
                    debug: debug == 1, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
                    appId: data.config.appId, // 必填，公众号的唯一标识
                    timestamp: data.config.timestamp, // 必填，生成签名的时间戳
                    nonceStr: data.config.nonceStr, // 必填，生成签名的随机串
                    signature: data.config.signature, // 必填，签名，见附录1
                    jsApiList: [
                        "onMenuShareTimeline",
                        "onMenuShareAppMessage",
                        "hideMenuItems",
                        "chooseImage",
                        "getLocalImgData",
                        "uploadImage",
                    ],
                });
                wx.ready(() => {
                    callback && callback(wx);
                });
            }
        );
    }

    handleUploadItemPic(recordId, imgKey) {
        // console.log(recordId,imgKey)
        // upload 操作
        const {pushedProductList} = this.state;

        let idx, updateProduct;

        pushedProductList.forEach((o, i) => {
            if (o.recordId === recordId) {
                updateProduct = o;
                idx = i;
            }
        });
        pushedProductList[idx] = {...updateProduct, picUrl: imgKey};
        this.setState({
            pushedProductList,
        });
    }

    componentDidMount() {
        this.configWx((wx) => {
            this.setState({
                // show: true,
                wx,
            });
            setTimeout(() => {
                this.showRule();
            }, 500);
        });
    }

    handleFilterSelect(word, option) {
        // console.log(word, option)
        word = this.keywordFilter(word);
        if (word.length > 0) {
            const {props} = option;
            const {children} = props;
            return children.toLowerCase().indexOf(word.toLowerCase()) >= 0;
        }
        return true;
    }

    handlePushProduct() {
        let {productId, number, productList, pushedProductList} = this.state;

        number = parseInt(number);
        // if (pushedProductList.filter(({ recordId }) => recordId === productId).length > 0) {
        //   Toast.fail('已添加过该商品')
        //   return
        // }
        if (pushedProductList.length === 10) {
            Toast.fail("最多可添加10个商品");
            return;
        }

        const product = productList.filter(
            ({recordId}) => recordId === productId
        )[0];

        if (!product) {
            Toast.fail("请选择产品");
            return;
        }
        if (isNaN(number) || number == 0) {
            Toast.fail("请输入正确的数量");
            return;
        }

        pushedProductList.push({
            ...product,
            number,
        });
        let tempProductId;
        this.setState({
            pushedProductList,
            productId: tempProductId,
            number: ' ',
        });
    }

    handleUploadJHD(jhdUrl) {
        this.setState({
            jhdUrl,
        });
    }

    handleSelectLeave() {
        const {productId, keyword = ""} = this.state;
        if (!productId && keyword.length > 0) {
            this.setState({
                keyword: "",
                productList: [],
            });
        }
    }

    handleSetJXH(record, i, val) {
        const {pushedProductList} = this.state;
        record.dingdanHao = val;
        console.log('record', record);
        record.isEditJXH = false;
        pushedProductList[i] = record;
        this.setState({
            pushedProductList,
        });
    }

    showRule() {
        const style = {
            position: "absolute",
            top: 0,
        };
        const that = this;
        const ruleIsOpen = getStore("ruleIsOpen");
        if (ruleIsOpen) {
            this.setState({
                show: true,
            });
            return;
        }
        Mask(
            <PopPic
                src={require("img/jfRule/1.png")}
                maxWidth="100%"
                style={style}
                onClose={() => {
                    Mask(
                        <PopPic
                            src={require("img/jfRule/2.png")}
                            maxWidth="100%"
                            style={style}
                            onClose={() => {
                                Mask(
                                    <PopPic
                                        src={require("img/jfRule/3.png")}
                                        maxWidth="100%"
                                        style={style}
                                        onClose={() => {
                                            Mask(
                                                <PopPic
                                                    src={require("img/jfRule/4.png")}
                                                    maxWidth="100%"
                                                    style={style}
                                                    onClose={() => {
                                                        Mask(
                                                            <PopPic
                                                                src={require("img/jfRule/5.png")}
                                                                maxWidth="100%"
                                                                style={style}
                                                                onClose={() => {
                                                                    that.setState(
                                                                        {
                                                                            show: true,
                                                                        },
                                                                        () => {
                                                                            setTimeout(() => {
                                                                                const el = document.querySelector(
                                                                                    ".ant-select-search__field"
                                                                                );
                                                                                el.addEventListener(
                                                                                    "focus",
                                                                                    iptFocus,
                                                                                    false
                                                                                );
                                                                                el.addEventListener(
                                                                                    "blur",
                                                                                    iptBlur,
                                                                                    false
                                                                                );
                                                                            }, 200);
                                                                        }
                                                                    );
                                                                    setStore("ruleIsOpen", "1");
                                                                }}
                                                            />,
                                                            {
                                                                maskStyle: {opacity: ".7"},
                                                            }
                                                        );
                                                    }}
                                                />,
                                                {
                                                    maskStyle: {opacity: ".7"},
                                                }
                                            );
                                        }}
                                    />,
                                    {
                                        maskStyle: {opacity: ".7"},
                                    }
                                );
                            }}
                        />,
                        {
                            maskStyle: {opacity: ".7"},
                        }
                    );
                }}
            />,
            {
                maskStyle: {opacity: ".7"},
            }
        );
    }

    render() {
        const {
            productList = [],
            company = "",
            number,
            show,
            pushedProductList,
            productId,
            jhdUrl = "",
            wx,
        } = this.state;
        console.log('number', number);
        if (!show) {
            return <div></div>;
        }
        return (
            <div style={{color: "#fff", textAlign: "center", paddingTop: ".4rem"}}>
                <div
                    style={{
                        width: "3.3rem",
                        height: ".4rem",
                        lineHeight: ".25rem",
                        marginBottom: ".25rem",
                        marginLeft: "calc(50% - 1.65rem)",
                        backgroundImage: `url(${require("img/jflr/search.png")})`,
                        backgroundSize: "100% 100%",
                        textAlign: "left",
                    }}
                >
                    <Select
                        onSearch={this.handleSearch}
                        onBlur={this.handleSelectLeave}
                        value={productId}
                        style={{
                            width: "75%",
                            border: 0,
                            paddingLeft: "12%",
                            position: "relative",
                            top: "0.02rem",
                        }}
                        onChange={(val) => this.handleInputChange({val}, "productId")}
                        showSearch
                        placeholder="请选择产品"
                        filterOption={this.handleFilterSelect}
                    >
                        {productList.map((o) => (
                            <Option value={o.recordId} key={o.name} data-val={o}>
                                {o.name.replace("3M ENVISION", "")}
                            </Option>
                        ))}
                    </Select>
                    <TextInput
                        type="number"
                        style={{
                            width: ".33rem",
                            position: "relative",
                            left: ".42rem",
                            height: ".25rem",
                            color: "#000",
                            fontSize: ".1rem",
                            top: "0.02rem",
                        }}
                        value={number}
                        maxLength="5"
                        onChange={(e) => this.handleInputChange(e, "number")}
                    />

                </div>
                <Btn
                    style={{
                        width: "2.05rem",
                        marginBottom: ".25rem",
                    }}
                    onClick={this.handlePushProduct}
                >
                    添加核算
                </Btn>

                <div
                    style={{
                        width: "3.22rem",
                        position: "relative",
                        marginLeft: "calc(50% - 1.61rem)",
                        height: "2.64rem",
                        background: "rgba(255,255,255,1)",
                        boxShadow: "0px 0px 19px 3px rgba(149,113,113,0.2)",
                        borderRadius: ".05rem",
                        padding: ".25rem .2rem",
                    }}
                >
                    <Flex
                        justify="between"
                        style={{
                            color: "#000",
                            fontSize: ".13rem",
                            paddingBottom: ".14rem",
                            borderBottom: ".005rem solid #d2d2d2",
                        }}
                    >
                        <span style={{width: "0.9rem"}}>产品名称</span>
                        <span
                            style={{
                                display: "inline-block",
                                width: ".8rem",
                                textAlign: "center",
                            }}
                        >
              数量
            </span>
                        <span
                            style={{
                                display: "inline-block",
                                width: ".8rem",
                                textAlign: "center",
                            }}
                        >
              总积分
            </span>
                        <span style={{display: 'inline-block', width: '.7rem', textAlign: 'center'}}>订单号</span>
                        <span
                            style={{
                                display: "inline-block",
                                width: "1.1rem",
                                textAlign: "center",
                            }}
                        >
              <span>上传图片</span><br/>
              <span
                  style={{
                      fontSize: '0.11rem'
                  }}
              >(收货证明)</span>
            </span>
                    </Flex>
                    <div
                        style={{
                            overflow: "hidden",
                            overflowY: "scroll",
                            height: "calc(100% - .3rem)",
                        }}
                    >
                        {pushedProductList.map((o, i) => (
                            <DetailItem
                                {...o}
                                wx={wx}
                                onSetJXH={(val) => this.handleSetJXH(o, i, val)}
                                key={Math.random()}
                                handleUpload={this.handleUploadItemPic}
                            />
                        ))}
                    </div>
                </div>

                <Btn onClick={this.handleSubmit}>确认并提交</Btn>
                <Home/>
            </div>
        );
    }
}

export default Detail;

import React from 'react'
import { Carousel, Flex, Progress, Result } from 'antd-mobile'
import { browserHistory } from 'react-router'
import Mask from 'component/mask'
import PopPic from 'component/pop-pic'
import Btn from 'component/btn'
import Home from 'component/home'
import RightArrow from 'component/right-arrow'
import DetailInfo from './detail-info'
import { getStore, get, moment } from 'mulan-lib'
import { processRequest } from 'lib'

function getStatus(status) {
  if (status == 'N') return '审核中';
  else if (status == 'F') return '审核失败';
  else if (status == 'Y') return '审核成功';
}

const DetailItem = ({ JF, onClick }) => {
  const { createTime = '2019/04/05', type = '积分兑换', total = '+500' ,status='N'} = JF
  return (
    <Flex onClick={ onClick } justify='between' style={{ padding: '.14rem 0', color: '#434343', fontSize: '.14rem', borderBottom: '.005rem solid #d2d2d2' }}>
      <span>{ moment('YYYY-MM-DD')(createTime) }</span>
      <span>{ type }</span>
      <span style={{ color: '#FF0000' }}>{ total }</span>
      <span style={{ color: '#888' }}>{getStatus(status)}</span>
      <span><RightArrow borderColor='#434343' /></span>
    </Flex>
  )
}

class Detail extends React.Component {
  constructor(props) {
    super(props)
    const companyInfo = getStore('companyinfo', 'session') || {}
    const { api_u_key = '' } = getStore('userinfo', 'session') || {}
    this.state = {
      companyInfo,
      api_u_key
    }
    this.handleGetInfo = this.handleGetInfo.bind(this)
  }
  fetchJFList() {
    const { api_u_key } = this.state
    processRequest(
      get('/api/queryJifenList', { api_u_key, from: 0, max: 999 }),
      data => {
        console.log(data)
        const { list = [], point } = data
        this.setState({
          list, point
        })
      }
    )
  }
  componentDidMount() {
    this.fetchJFList()
  }
  handleGetInfo(recordId) {
    Mask(<DetailInfo jifenId={ recordId } />)
  }

  render() {
    const { companyInfo = {}, list = [], point = 0 } = this.state

    return (
      <div style={{ color: '#fff' }}>
        <Flex justify='center' align='center' style={{ height: '1.8rem', backgroundImage: `url(${ require('img/jfmx/banner.png') })`, backgroundSize: '100% 100%' }}>
          <div>
            <div style={{ fontSize: '.12rem', textAlign: 'center' }}>总积分</div>
            <div style={{ fontSize: '.34rem', textAlign: 'center', fontFamily: 'SourceHanSansCN-Bold' }}>{ point }</div>
            <div style={{ fontSize: '.14rem', textAlign: 'center', width: '.87rem', height: '.25rem', lineHeight: '.25rem', backgroundColor: 'rgba(255,255,255,.45)', borderRadius: '.13rem' }} onClick={ () => Mask(<PopPic pic='jqqd' />) }>积分兑换 <RightArrow /></div>
          </div>
        </Flex>
        <div style={{ width: '3.22rem', position: 'relative', marginLeft: 'calc(50% - 1.61rem)', marginTop: '-.2rem', height: '3.38rem', background: 'rgba(255,255,255,1)', boxShadow: '0px 0px 19px 3px rgba(149,113,113,0.2)', borderRadius: '.05rem' }}>
          <div style={{ padding: '.15rem 0', textAlign: 'center', fontSize: '.18rem', color: '#333', borderBottom: '3px solid linear-gradient(163deg,rgba(241,66,66,1) 0%,rgba(245,133,133,1) 100%)' }}>
            积分明细
          </div>
          <div style={{ height: '.03rem', background: 'linear-gradient(163deg,rgba(241,66,66,1) 0%,rgba(245,133,133,1) 100%)', borderRadius: '.03rem' }} />
          <div style={{ padding: '0 .2rem', height: 'calc(100% - .7rem)', overflow: 'hidden', overflowY: 'scroll' }}>
            {
              list.length === 0 && (
                <Flex style={{ height: '100%' }}><Result
                  title="暂无明细"
                  message="赶紧去录入吧~"
                /></Flex>
              )
            }
            {
              list.length > 0 && list.map(o => {
                return (<DetailItem onClick={ () => this.handleGetInfo(o.recordId) } JF={ o } />)
              })
            }
          </div>
        </div>
        <Btn style={{
          width: '2.05rem',
        }} onClick={  () => browserHistory.push('/channel') }>
          积分录入
        </Btn>
        <Home />
      </div>
    )
  }
}

export default Detail

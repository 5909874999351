import React from "react";
import { Carousel, Flex, Progress, Picker, Toast } from "antd-mobile";
import TextInput, { iptBlur, iptFocus } from "component/text-input";
import Upload from "component/upload-m";

class App extends React.Component {
  constructor(props) {
    super(props);
    const { dingdanHao = "" } = this.props;
    this.state = {
      dingdanHao,
      isEdit: !(dingdanHao.length > 0),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  handleChange({ currentTarget }) {
    this.setState({
      dingdanHao: currentTarget.value,
    });
  }
  handleBlur({ currentTarget }) {
    const { onSetJXH } = this.props;
    this.setState({
      isEdit: false,
    });
    onSetJXH && onSetJXH(currentTarget.value);
  }
  handleEdit() {
    this.setState({
      isEdit: true,
    });
  }
  render() {
    const {
      wx,
      name,
      point,
      number,
      picUrl,
      handleUpload,
      recordId,
    } = this.props;
    const { dingdanHao, isEdit } = this.state;
    // console.log(isEdit)
    return (
      <Flex
        justify="between"
        style={{
          color: "#333",
          fontSize: ".1rem",
          padding: ".1rem 0",
          borderBottom: ".005rem solid #d2d2d2",
        }}
      >
        <span style={{ width: ".8rem" }}>{name}</span>
        <span
          style={{
            display: "inline-block",
            width: ".8rem",
            textAlign: "center",
            color: "#FF0000",
          }}
        >
          {number}
        </span>
        <span
          style={{
            display: "inline-block",
            width: ".8rem",
            textAlign: "center",
            color: "#FF0000",
          }}
        >
          {point * number}
        </span>
        <span style={{ display: 'inline-block', width: '.7rem', textAlign: 'center', color: '#FF0000' }}>
          {
            !isEdit ? <span style={{
              width: '.7rem', display: 'inline-block', wordWrap: 'break-word'
            }} onClick={ this.handleEdit }>{ dingdanHao }</span>
                                  : <TextInput className='juanxinhao-edit'
                                               style={{
                                                 width: '.7rem',
                                                 border: '1px solid #ccc',
                                                 borderRadius: '.03rem',
                                                 textAlign:'center',
                                                 padding: '0 .05rem'
                                               }} value={ dingdanHao } placeholder='点击输入' onChange={ this.handleChange } onBlur={ this.handleBlur } />
                        }
        </span>
        <span
          style={{
            display: "inline-block",
            width: "1.1rem",
            textAlign: "center",
            color: "#FF0000",
          }}
        >
          <Upload
            wx={wx}
            onChange={({ imgKey }) => handleUpload(recordId, imgKey)}
          >
            <img
              src={require(`img/jflr/${picUrl ? "ed" : "plus"}.png`)}
              style={{ width: ".15rem" }}
            />
          </Upload>
        </span>
      </Flex>
    );
  }
}

export default App;
